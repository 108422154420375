import { useMemo } from "react";
import { useStrapiService } from "./strapi";

export const fromDTO = (dto) => {
  return {
    logo: dto.logo?.data?.attributes?.url,
    name: dto.name,
    type: dto.type,
  };
};

export const useClientsContentService = () => {
  const { fetchMultipleObjects } = useStrapiService();
  const fetchClientsSummary = useMemo(
    () => fetchMultipleObjects ? async () =>
      fetchMultipleObjects(
        "clients",
        fromDTO,
        "populate=logo"
      ) : undefined,
    [fetchMultipleObjects]
  );

  return { fetchClientsSummary };
};
