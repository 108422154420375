import React from "react";
import NavBar from "../components/Navbar/NavBar";
import { useGlobalState } from "../contexts/GlobalState";
import { ContactUsButton } from "./ContactUsButton";

const Hero = () => {
  const { state: content } = useGlobalState();
  return (
    <div id="hero" className="mt-20">
      <div>
        <NavBar />
      </div>

      <div
        className="m-auto overflow-hidden mt-8 lg:mt-4 h-5/6"
        data-aos="zoom-in"
      >
        <div
          id="hero"
          className="flex flex-col lg:flex-row py-8 justify-between text-center lg:text-left"
        >
          <div className="mx-4 p-2 md:p-12 flex-1">
            <div
              className="flex flex-col justify-center"
              data-aos="zoom-in"
              data-aos-delay="200"
            >
              <div className="md:mr-5">
                <h1 className="mb-5 md:text-4xl text-3xl font-bold text-primary-dark">
                  {content?.hero?.mainTitle}
                </h1>
                <div className="text-xl tracking-tight mb-5 text-gray-500">
                  {content?.hero?.subTitle}
                </div>
              </div>
              <div className="mb-4 space-x-0 md:space-x-2 md:mb-8">
                <ContactUsButton />
              </div>
            </div>
            <div
              className="flex flex-col items-center justify-center w-full lg:w-1/2"
              data-aos="fade-up"
              data-aos-delay="700"
            >
            </div>
          </div>
          <div className="flex-1 flex items-center justify-center§" >
            <img alt="card img" className="lg:pr-12" src={content?.hero?.image} />
          </div>
        </div>
      </div>
    </div>
  );
};

export default Hero;
