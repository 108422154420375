import React from "react";
import { useGlobalState } from "../contexts/GlobalState";

export const FacebookLink = () => {
  const { state: content } = useGlobalState();

  return (
    <a
      href={content?.data?.facebookUrl}
      target="_blank"
      rel="noreferrer"
      className="rounded-full flex justify-center bg-white h-8 text-primary-dark  w-8  mx-1 text-center pt-1"
    >
      <svg
        xmlns="http://www.w3.org/2000/svg"
        width="24"
        height="24"
        viewBox="0 0 24 24"
        className="fill-current font-black hover:animate-pulse"
      >
        <path d="M13.397 20.997v-8.196h2.765l.411-3.209h-3.176V7.548c0-.926.258-1.56 1.587-1.56h1.684V3.127A22.336 22.336 0 0 0 14.201 3c-2.444 0-4.122 1.492-4.122 4.231v2.355H7.332v3.209h2.753v8.202h3.312z"></path>
      </svg>
    </a>
  );
};
