import { useMemo } from "react";
import { useStrapiService } from "./strapi";

export const fromDTO = (dto) => {
  return {
    //headImage: `${apiUrl}${dto.head_image?.data?.attributes?.url}`,
    title: dto.title,
    summary: dto.summary,
  };
};

export const usePortfolioContentService = () => {
  const { fetchMultipleObjects } = useStrapiService();

  const fetchPortfolioSummary = useMemo(
    () => fetchMultipleObjects ? async () =>
      fetchMultipleObjects(
        "portfolios",
        fromDTO
        //"populate=head_image"
      ) : undefined,
    [fetchMultipleObjects]
  );

  return { fetchPortfolioSummary };
};
