import React from "react";
import { Link } from "react-router-dom";
import { useGlobalState } from "../contexts/GlobalState";

export const ContactUsButton = () => {
  const { state: content } = useGlobalState();
  return (
    <Link
      to="/contact"
      className="text-white bg-primary-dark hover:bg-primary inline-flex items-center justify-center w-full px-6 py-2 my-4 text-lg shadow-xl rounded-2xl sm:w-auto sm:mb-0 group whitespace-nowrap"
    >
      {content?.global?.contactUs}
      <svg
        className="w-4 h-4 ml-1 group-hover: translate-x-2"
        xmlns="http://www.w3.org/2000/svg"
        viewBox="0 0 20 20"
        fill="currentColor"
      >
        <path
          fillRule="evenodd"
          d="M10.293 3.293a1 1 0 011.414 0l6 6a1 1 0 010 1.414l-6 6a1 1 0 01-1.414-1.414L14.586 11H3a1 1 0 110-2h11.586l-4.293-4.293a1 1 0 010-1.414z"
          clipRule="evenodd"
        ></path>
      </svg>
    </Link>
  );
};
