import React from "react";

const clientImage = {
  height: "5rem",
  width: "auto",
  //mixBlendMode: "colorBurn",
};

export const ClientCard = ({ logo }) => {
  return (
    <div className="flex flex-col">
      <div
        style={clientImage}
        className="overflow-hidden flex justify-center transition-all ease-in-out w-1/6"
      >
        {logo ? <img src={logo} alt="client" className="object-contain h-auto max-w-full"
          style={{ maxHeight: '100%', maxWidth: '100%' }} /> : undefined}
      </div>
    </div>
  );
};
