import { useMemo } from "react";
import { useStrapiService } from "./strapi";

export const fromDTO = (dto) => {
  return {
    siteTitle: dto.site_title,
    hero: {
      mainTitle: dto.hero_main_title,
      subTitle: dto.hero_sub_title,
      image: `${dto.hero_image?.data?.attributes?.url}`,
    },
    menu: {
      about: dto.menu_about,
      services: dto.menu_services,
      portfolio: dto.menu_portfolio,
      contactUs: dto.menu_contact_us,
      links: dto.menu_links,
      socialLinks: dto.menu_social_links,
      followUs: dto.menu_follow_us,
      blogLabel: dto.menu_blog_label,
      blogLink: dto.menu_blog_link,
    },
    intro: {
      mainTitle: dto.intro_main_title,
      content1: dto.intro_content_1,
      content2: dto.intro_content_2,
      image: `${dto.intro_img?.data?.attributes?.url}`,
    },
    global: {
      learnMore: dto.global_learn_more,
      contactUs: dto.global_contact_us,
      services: dto.global_services,
      allRightsReserved: dto.global_all_rights_reserved,
    },
    services: {
      subTitle: dto.services_sub_title,
      weBuildTitle: dto.services_we_build_title,
      weBuildContent: dto.services_we_build_content,
      weCollaborateTitle: dto.services_we_collaborate_title,
      weCollaborateContent: dto.services_we_collaborate_content,
    },
    portfolio: {
      title: dto.portfolio_title,
    },
    clients: {
      title: dto.clients_title,
      subTitle: dto.clients_subtitle,
      type: dto.clients_type,
    },
    pastEmployers: {
      title: dto.past_employers_title,
      subTitle: dto.past_employers_subtitle,
    },
    cta: {
      title: dto.cta_title,
      subTitle: dto.cta_subtitle,
      sendMessage: dto.cta_send_message,
    },
    data: {
      facebookUrl: dto.data_facebook_url,
      linkedinUrl: dto.data_linkedin_url,
    },
    consultation: {
      title: dto.consultation_title,
      content: dto.consultation_content,
      cta: dto.consultation_cta,
      calendlyLink: dto.consultation_calendly_link,
    },
    contact: {
      offceAddress: dto.contact_office_address,
      callUs: dto.contact_call_us,
      emailUs: dto.contact_email_us,
    },
  };
};

export const useGlobalContentService = () => {
  const { fetchSingleObject } = useStrapiService();
  const fetchGlobalContent = useMemo(
    () => fetchSingleObject ? async () =>
      fetchSingleObject(
        "global-setting",
        fromDTO,
        "populate=hero_image,intro_img"
      ) : undefined,
    [fetchSingleObject]
  );

  return { fetchGlobalContent };
};
