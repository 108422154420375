import { useMemo } from "react";
import { useStrapiService } from "./strapi";

export const fromDTO = (dto) => {
  return {
    headImage: dto.head_image?.data?.attributes?.url,
    title: dto.title,
    introduction: dto.introduction,
  };
};

export const useServicesContentService = () => {
  const { fetchMultipleObjects } = useStrapiService();
  const fetchServicesSummary = useMemo(
    () => fetchMultipleObjects ? async () =>
      fetchMultipleObjects(
        "services",
        fromDTO,
        "populate=head_image"
      ) : undefined,
    [fetchMultipleObjects]
  );

  return { fetchServicesSummary };
};
