import React, { useMemo } from "react";
import { HashLink } from "react-router-hash-link";
import { useGlobalState } from "../contexts/GlobalState";
import { LinkedinLink } from "./LinkedinLink";
import { FacebookLink } from "./FacebookLink";

const Footer = () => {
  const { state: content } = useGlobalState();

  const serviceTitles = useMemo(
    () =>
      content?.servicesSummary?.map((service) => (
        <li className="mb-2" key={service.title}>
          <div
            //to="#"
            className="text-gray-700 hover:text-primary-dark hover:tracking-wider transition duration-250 ease-in-out"
          >
            {service.title}
          </div>
        </li>
      )),
    [content?.servicesSummary]
  );

  return (
    <footer>
      <div className="footer max-w-full mx-auto px-4 sm:px-6 bg-gray-100 border-t border-b py-30">
        {/* Top area: Blocks */}
        <div className="grid sm:grid-cols-12 gap-5 py-8 md:py-12 border-t border-gray-200 lg:ml-11">
          {/* 1st block */}
          <div className="col-span-12 lg:col-span-4">
            <div className="p-8 bg-gray-200 text-gray-600 text-center rounded-lg xl:w-80 mx-auto">
              <h3 className="font-bold text-4xl mb-4">Garlic Tech Kft.</h3>
              <div className="flex text-md font-medium text-gray-600 gap-16 justify-center">
                <h5 className="mb-1 font-bold">Budapest</h5>
                <h5 className="mb-1 font-bold">Debrecen</h5>
              </div>
            </div>
          </div>

          {/* 2nd block */}
          <div className="col-span-6 md:col-span-6 lg:col-span-1 ml-7 mx-auto">
            <h6 className="text-gray-700  text-xl font-bold uppercase mb-4">
              {content?.menu?.links}
            </h6>
            <ul className="text-md">
              <li className="mb-2">
                <HashLink
                  to="/#hero"
                  className="text-gray-700 hover:text-primary-dark hover:tracking-wider transition duration-250 ease-in-out"
                >
                  {content?.menu?.about}
                </HashLink>
              </li>
              <li className="mb-2">
                <HashLink
                  to="/#services"
                  className="text-gray-700 hover:text-primary-dark hover:tracking-wider transition duration-250 ease-in-out"
                >
                  {content?.menu?.services}
                </HashLink>
              </li>
              <li className="mb-2">
                <HashLink
                  to="/contact"
                  className="text-gray-700 hover:text-primary-dark hover:tracking-wider transition duration-250 ease-in-out"
                >
                  {content?.menu?.contactUs}
                </HashLink>
              </li>
            </ul>
          </div>

          {/* 3rd block */}
          <div className="col-span-6 md:col-span-6 lg:col-span-4 mx-auto">
            <h6 className="text-gray-700 text-xl font-bold mb-4 uppercase">
              {content?.menu?.services}
            </h6>
            <ul className="text-md">{serviceTitles}</ul>
          </div>

          {/* 4th block */}
          <div className="col-span-12 text-center mx-auto lg:col-span-3 font-bold uppercase text-gray-700">
            <div className="text-xl mb-6">{content?.menu?.socialLinks}</div>

            <div className="mx-auto text-center mt-2">
              <ul className="flex justify-center mb-4 md:mb-0">
                <li>
                  <LinkedinLink />
                </li>
                <li className="ml-4">
                  <FacebookLink />
                </li>
              </ul>
            </div>
          </div>
        </div>

        <div className="flex flex-wrap items-center md:justify-between justify-center mx-auto px-4">
          <div className="w-full md:w-4/12 px-4 mx-auto text-center py-2">
            <div className="text-sm text-gray-200 font-semibold py-1">
              Copyright &copy; {new Date().getFullYear()}
              {"  "}
              <HashLink to="#" className=" hover:text-gray-900">
                Garlic Tech Kft
              </HashLink>
              . {content?.global?.allRightsReserved}
            </div>
          </div>
        </div>
      </div>
    </footer>
  );
};
export default Footer;
