import React, { useEffect } from "react";
import AOS from "aos";
import "aos/dist/aos.css";
import "./index.css";
import { BrowserRouter as Router, Routes, Route } from "react-router-dom";
// All pages
import Home from "./pages/Home";
import Contact from "./pages/Contact";

import { useGlobalContentService } from "./services/global-content";
import { useDocTitle } from "./components/CustomHook";
import ScrollToTop from "./components/ScrollToTop";

import { useGlobalState } from "./contexts/GlobalState";
import { useServicesContentService } from "./services/services";
import { usePortfolioContentService } from "./services/portfolio";
import { useClientsContentService } from "./services/clients";

function App() {
  const { state: globalState, dispatch } = useGlobalState();
  const { fetchGlobalContent } = useGlobalContentService();
  const { fetchServicesSummary } = useServicesContentService();
  const { fetchPortfolioSummary } = usePortfolioContentService();
  const { fetchClientsSummary } = useClientsContentService();
  const [, setDocTitle] = useDocTitle(globalState.siteTitle);

  useEffect(() => {
    const aos_init = () => {
      AOS.init({
        once: true,
        duration: 1000,
        easing: "ease-out-cubic",
      });
    };

    window.addEventListener("load", () => {
      aos_init();
    });
  }, []);

  useEffect(() => {
    if (fetchGlobalContent) {
      fetchGlobalContent().then((globalState) => {
        dispatch({ type: "UPDATE_GLOBAL_STATE", payload: globalState })
      }
      );
    }
  }, [fetchGlobalContent, dispatch]);

  useEffect(() => {
    if (fetchServicesSummary) {
      fetchServicesSummary().then((content) =>
        dispatch({
          type: "UPDATE_GLOBAL_STATE",
          payload: { servicesSummary: content },
        })
      );
    }
  }, [fetchServicesSummary, dispatch]);

  useEffect(() => {
    if (fetchPortfolioSummary) {
      fetchPortfolioSummary().then((content) => {
        return dispatch({
          type: "UPDATE_GLOBAL_STATE",
          payload: { portfolioSummary: content },
        });
      });
    }
  }, [dispatch, fetchPortfolioSummary]);

  useEffect(() => {
    if (fetchClientsSummary) {
      fetchClientsSummary().then((content) => {
        return dispatch({
          type: "UPDATE_GLOBAL_STATE",
          payload: { clientsSummary: content },
        });
      });
    }
  }, [dispatch, fetchClientsSummary]);

  useEffect(() => {
    if (globalState.siteTitle) {
      setDocTitle(globalState.siteTitle);
    }
  }, [globalState.siteTitle, setDocTitle]);

  return (
    <Router>
      <ScrollToTop>
        <Routes>
          <Route path="/" element={<Home />} />
          <Route path="/contact" element={<Contact />} />
        </Routes>
      </ScrollToTop>
    </Router>
  );
}

export default App;
