import React, { createContext, useReducer, useContext, useMemo } from "react";
import { useDefaults } from "../services/defaults";
import PropTypes from "prop-types";

export const GlobalStateContext = createContext(null);

export const GlobalStateReducer = (state, action) => {
  switch (action.type) {
    case "SET_GLOBAL_STATE":
      return action.payload;
    case "UPDATE_GLOBAL_STATE":
      return {
        ...state,
        ...action.payload,
      };
    default:
      return state;
  }
};

export function GlobalStateProvider({ children }) {
  const defaults = useDefaults();
  const [state, dispatch] = useReducer(GlobalStateReducer, defaults);
  const value = useMemo(() => ({ state, dispatch }), [state, dispatch]);

  return (
    <GlobalStateContext.Provider value={value}>
      {children}
    </GlobalStateContext.Provider>
  );
}

GlobalStateProvider.propTypes = {
  children: PropTypes.node.isRequired,
};

export function useGlobalState() {
  const context = useContext(GlobalStateContext);

  if (context === undefined) {
    throw new Error(
      "useGlobalState must be used within a GlobalStateContext.Provider"
    );
  }

  return context;
}
