import axios from "axios";
import { useEnvironment } from "./environment";
import { useMemo } from "react";
import { useGlobalState } from "../contexts/GlobalState";
import { map } from "ramda";

export const useStrapiService = () => {
  const environment = useEnvironment();
  const {
    state: { language },
  } = useGlobalState();

  const localeSlug = useMemo(() => {
    return language ? `locale=${language}` : "";
  }, [language]);

  const fetchData = useMemo(
    () => async (apiSlug, queryParams) => {
      const result = await axios.get(
        `${environment.STRAPI_URL}/api/${apiSlug}?${localeSlug}&${queryParams ?? ""
        }`
      );
      return result.data.data;
    },
    [environment.STRAPI_URL, localeSlug]
  );

  const fetchSingleObject = useMemo(
    () => async (apiSlug, fromDTO, queryParams) =>
      fetchData(apiSlug, queryParams).then((data) => fromDTO(data.attributes)),
    [fetchData]
  );

  const fetchMultipleObjects = useMemo(
    () => async (apiSlug, fromDTO, queryParams) => {
      return fetchData(apiSlug, queryParams).then(
        map((item) => ({
          id: item.id,
          ...fromDTO(item.attributes),
        }))
      );
    },
    [fetchData]
  );

  if (!language) {
    return {}
  }

  return { fetchSingleObject, fetchMultipleObjects };
};
