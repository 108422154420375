import React from "react";
import NavBar from "../components/Navbar/NavBar";
import Footer from "../components/Footer";
import { FacebookLink } from "../components/FacebookLink";
import { LinkedinLink } from "../components/LinkedinLink";
import { useGlobalState } from "../contexts/GlobalState";
import { Link } from "react-router-dom";

const Contact = () => {
  const { state: content } = useGlobalState();
  window.scrollTo(0, 0);

  return (
    <>
      <div>
        <NavBar />
      </div>
      <div
        id="contact"
        className="flex justify-center items-center mt-8 w-full bg-white py-12 lg:py-24 "
      >
        <div
          className="container mx-auto my-8 px-4 lg:px-20"
          data-aos="zoom-in"
        >
          <div className="w-full bg-white p-8 my-4 md:px-12 lg:w-9/12 lg:pl-20 lg:pr-40 mr-auto rounded-2xl shadow-2xl">
            <div className="flex">
              <h1 className="font-bold text-center lg:text-left text-primary-dark uppercase text-4xl">
                {content?.consultation?.title}
              </h1>
            </div>
            <div className="grid grid-cols-1 gap-5 mt-5">
              <div className="my-4">{content?.consultation?.content}</div>
            </div>
            <div className="my-2 w-1/2 lg:w-2/4">
              <Link
                to={content?.consultation?.calendlyLink}
                target="_blank"
                id="submitBtn"
                className="uppercase text-sm font-bold tracking-wide bg-gray-500 hover:bg-primary-dark text-gray-100 p-3 rounded-lg w-full 
                                    focus:outline-none focus:shadow-outline whitespace-nowrap"
              >
                {content?.consultation?.cta}
              </Link>
            </div>
          </div>
          <div className="w-full  lg:-mt-96 lg:w-2/6 px-8 py-6 ml-auto bg-primary-dark rounded-2xl">
            <div className="flex flex-col text-white">
              <div className="flex my-4 w-2/3 lg:w-3/4">
                <div className="flex flex-col">
                  <i className="fas fa-map-marker-alt pt-2 pr-2" />
                </div>
                <div className="flex flex-col">
                  <h2 className="text-2xl">{content?.contact?.offceAddress}</h2>
                  <p className="text-gray-400">
                    1139 Budapest, Tahi u. 40/a, Hungary
                  </p>
                  <p className="ml-2 text-gray-400 text-sm">+</p>
                  <p className="text-gray-400">
                    4025 Debrecen, Simonffy u. 4-6 (xPonential), Hungary
                  </p>
                </div>
              </div>

              <div className="flex my-4 w-2/3 lg:w-1/2">
                <div className="flex flex-col">
                  <i className="fas fa-phone-alt pt-2 pr-2" />
                </div>

                <div className="flex flex-col">
                  <h2 className="text-2xl">{content?.contact?.callUs}</h2>
                  <p className="text-gray-400">+36 70 310 6079</p>

                  <div className="mt-5">
                    <h2 className="text-2xl">{content?.contact?.emailUs}</h2>
                    <p className="text-gray-400">contact@garlictech.com</p>
                  </div>
                </div>
              </div>

              <div className="flex my-4 w-2/3 lg:w-1/2">
                <LinkedinLink />
                <FacebookLink />
              </div>
            </div>
          </div>
        </div>
      </div>
      <Footer />
    </>
  );
};

export default Contact;
