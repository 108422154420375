import React from "react";
//import { useGlobalState } from "../contexts/GlobalState";
//import { PortfolioCard } from "./PortfolioCard";

const Portfolio = () => {
  //const { state: content } = useGlobalState();

  /*const portfolioCards = content?.portfolioSummary?.map((portfolio) => (
    <PortfolioCard
      key={portfolio.id}
      title={portfolio.title}
      summary={portfolio.summary}
    />
  ));
*/
  return <div id="portfolio"></div>;
  /* {
      <div className="my-4 py-4" id="portfolio">
       <h2 className="my-2 text-center text-3xl text-primary-dark uppercase font-bold">
         {content?.portfolio?.title}
       </h2>
       <div className="flex justify-center">
         <div className="w-24 border-b-4 border-primary-dark mb-8"></div>
       </div>
 
       <div className="px-4" data-aos="fade-down" data-aos-delay="600">
         <div className="grid sm:grid-cols-2 lg:grid-cols-4 gap-5">
           {portfolioCards}
         </div>
       </div>
     </div>
   }*/
};

export default Portfolio;
