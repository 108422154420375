import React from "react";
import { useGlobalState } from "../contexts/GlobalState";
import { ClientCard } from "./ClientCard";

const Clients = () => {
  const { state: content } = useGlobalState();

  const logoCards = content?.clientsSummary?.filter((client) => client.type === "client")
    .map((client) => (
      <ClientCard key={client.id} logo={client.logo} />
    ));

  return (
    <div className="mt-8">
      <section data-aos="fade-up">
        <div className="my-4 py-4">
          <h2 className="my-2 text-center text-3xl text-primary-dark uppercase font-bold">
            {content?.clients?.title}
          </h2>
        </div>

        <div className="p-16" data-aos="fade-in" data-aos-delay="600">
          <div className="grid grid-cols-2 sm:grid-cols-3 md:grid-cols-4 lg:grid-cols-6 gap-4">
            {logoCards}
          </div>
        </div>
      </section>
    </div>
  );
};

export default Clients;
