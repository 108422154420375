import React from "react";
import { useGlobalState } from "../contexts/GlobalState";
import { useSettings } from "../services/settings";

const handleUnsupportedLocale = (/** @type {string} */ locale, /** @type {string[]} */ supportedLocales, /** @type {string} */ defaultLocale) => {
  if (!locale) return defaultLocale;

  if (!supportedLocales.includes(locale)) {
    console.warn("Unsupported locale: ", locale);
    return defaultLocale;
  }

  return locale;
};

export const LanguageSelector = () => {
  const { dispatch } = useGlobalState();
  const { supportedLocales, defaultLocale } = useSettings();

  const handleLanguageChange = (/** @type {string} */ selectedLanguage) => {
    const language = handleUnsupportedLocale(selectedLanguage, supportedLocales, defaultLocale);
    localStorage.setItem("language", language);

    dispatch({
      type: "UPDATE_GLOBAL_STATE",
      payload: { language },
    });
  };

  React.useEffect(() => {
    const storedLanguage = localStorage.getItem("language");
    const language = handleUnsupportedLocale(storedLanguage, supportedLocales, defaultLocale);

    dispatch({
      type: "UPDATE_GLOBAL_STATE",
      payload: { language },
    });
  }, [defaultLocale, dispatch, supportedLocales]);

  return (
    <div>
      <button className="pr-2" onClick={() => handleLanguageChange("en")}>
        🇺🇸
      </button>
      <button onClick={() => handleLanguageChange("hu")}>🇭🇺</button>
    </div>
  );
};
