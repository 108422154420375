import React from "react";
import { HashLink } from "react-router-hash-link";
import { useGlobalState } from "../../contexts/GlobalState";

const NavLinks = ({ onClick }) => {
  const { state: content } = useGlobalState();

  return (
    <>
      <HashLink
        className="px-4 font-extrabold text-gray-500 hover:text-primary-dark"
        smooth
        to="/#hero"
        onClick={onClick}
      >
        {content?.menu?.about}
      </HashLink>
      <HashLink
        className="px-4 font-extrabold text-gray-500 hover:text-primary-dark"
        smooth
        to="/#services"
        onClick={onClick}
      >
        {content?.menu?.services}
      </HashLink>
      {/*<HashLink
        className="px-4 font-extrabold text-gray-500 hover:text-primary-dark"
        to="/#portfolio"
        onClick={onClick}
      >
        {content?.menu?.portfolio}
      </HashLink>
      */}
      <HashLink
        className="px-4 font-extrabold text-gray-500 hover:text-primary-dark"
        to="/contact"
        onClick={onClick}
      >
        {content?.menu?.contactUs}
      </HashLink>
      <a
        href={content?.menu?.blogLink}
        target="_blank"
        rel="noopener noreferrer"
        className="px-4 font-extrabold text-gray-500 hover:text-primary-dark"
      >
        {content?.menu?.blogLabel}
      </a>
    </>
  );
};

export default NavLinks;
