import React from "react";
import { useGlobalState } from "../contexts/GlobalState";
import { ContactUsButton } from "./ContactUsButton";

const Cta = () => {
  const { state: content } = useGlobalState();

  return (
    <div className="w-full flex items-center justify-center text-white cta">
      <div className="mx-8 w-full h-96 text-center lg:text-left py-16 px-12 flex lg:justify-between items-center">
        <div className="w-full flex flex-col lg:flex-row lg:justify-around">
          <div className="mb-4">
            <p className="text-2xl md:text-4xl font-bold mb-4">
              {content?.cta?.title}
            </p>
            <p className="text-lg md:text-2xl">{content?.cta?.subTitle}</p>
          </div>

          <div className="w-full lg:w-72 pt-6 lg:mx-12">
            <ContactUsButton />
          </div>
        </div>
      </div>
    </div>
  );
};

export default Cta;
